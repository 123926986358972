import { User } from "firebase";
import { Config } from "protractor";
import { Unit } from "./administrative";
import { Building } from "./buildings.interface";

export enum InvitationStatus {
  concreted = 'concreted',
  notCompleted = 'not_completed',
  createdApproved = 'created_approved',
  checked = 'checked',
  approved = 'approved',
  authorizedByCall = 'authorized_by_call',
  denied = 'denied',
  unauthorizedByCall = 'unauthorized_by_call',
  withoutResponseByCall = 'without_response_by_call',
  notAnswerByCall = 'not_answer_by_call',
  canceledCallByTenant = 'canceled_by_call',
  canceled = 'canceled',
  withoutTenant = 'without_tenant',
  updateWithoutConnection = 'update_without_connection',
  withoutConnection = 'without_connection',
  declined = 'declined',
  left = 'left',
}

export enum AccessEntryStatus {
  active = 'active',
  displacement = 'displacement',
  timeExceeded = 'time-exceeded',
  completed = 'completed',
  completedBySystem = 'completed-by-system',
  //Labels badges
  left = "left",
  entered = "entered",
  noLeave = "noLeave"
}

export enum AccessDoorStatus {
  active = 'active',
  displacement = 'displacement',
  timeExceeded = 'time-exceeded',
  omitted = 'omitted',
  completed = 'completed',
}

export const ACCESS_DOOR_ACTIVE_STATUS: String[] = [AccessDoorStatus.active, AccessDoorStatus.displacement, AccessDoorStatus.timeExceeded];

export const ACCESS_DOOR_ALLOWED_CHANGE_STATUS: String[] = [AccessDoorStatus.active, AccessDoorStatus.displacement];

export const ACCESS_DOOR_FINAL_STATUS: String[] = [AccessDoorStatus.completed, AccessDoorStatus.omitted];

export const ACCESS_ENTRY_ACTIVE_STATUS: String[] = [AccessEntryStatus.active, AccessEntryStatus.displacement, AccessEntryStatus.timeExceeded];

export const ACCESS_ENTRY_FINAL_STATUS: String[] = [AccessEntryStatus.completed, AccessEntryStatus.completedBySystem];

export enum InvitationPhoneCallStatus { }

export const INVITATION_FINAL_STATUS: String[] = [
  InvitationStatus.concreted,
  InvitationStatus.authorizedByCall,
  InvitationStatus.notCompleted,
  InvitationStatus.denied,
  InvitationStatus.unauthorizedByCall,
  InvitationStatus.left,
  InvitationStatus.canceled,
  InvitationStatus.withoutTenant,
  InvitationStatus.updateWithoutConnection,
  InvitationStatus.withoutConnection,
];

export const INVITATION_ALLOWED_CALL_CHANGE_STATUS: String[] = [
  InvitationStatus.checked,
  InvitationStatus.notAnswerByCall,
  InvitationStatus.withoutResponseByCall,
  InvitationStatus.updateWithoutConnection,
  InvitationStatus.withoutTenant,
  InvitationStatus.withoutConnection,
];

export const INVITATION_INTERMEDIATE_STATUS: String[] = [
  InvitationStatus.checked,
  InvitationStatus.approved,
  InvitationStatus.createdApproved,
  InvitationStatus.notAnswerByCall,
  InvitationStatus.withoutResponseByCall,
];
export const INVITATION_LEFT_STATUS: String[] = [
  InvitationStatus.denied,
  InvitationStatus.left,
  InvitationStatus.canceled,
  InvitationStatus.unauthorizedByCall,
];

export enum AccessCardStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
  EXPIRED = 'expired',
}

export enum AccessCardType {
  VISIT = 'visit', //Visitante
  PATIENT = 'patient', // Paciente
  COLLABORATOR = 'collaborator' // Colaborador
}

export enum AccessTypeMacro {
  COLLABORATOR = 'collaborator', // Colaborador
  VISIT = 'visit', //Visitante
  PROVIDER = 'provider' // Provedor
}

export enum AccessType {
  tenant = 'tenant',
  unique = 'unique',
  frequent = 'frequent'
}

export enum typeVehicle {
  car = "carro",
  motorcycle = "moto",
  other = "otro"
}

export class FrequentVehicle {
  plate?: string;
  color?: string;
  model?: string;
  brand?: string;
  typeVehicle?: string;
}

export class SecurityStateDTO {
  frequentAccessId: string;
  personInvitationType: PersonInvitationType;
  unit: {
    id: string;
    number: string;
  }
  date: Date | String;
  guest: {
    name: string;
    dni: string;
    phone: string;
    photo: string;
    email: string;
    identification?: Identification
  }
  status: string;
  accessEntryStatus: AccessEntryStatus;
  invitationId: string;
  accessEntry: {
    doorName: string,
    doorId: string,
    date: Date,
    status: AccessEntryStatus;
    checked: boolean
  }[];
  vehicle?: FrequentVehicle;
  lastMarking?: Date | string;
}

export class InvitationsHistoryDTO {
  invitationId: string;
  date: Date;
  status: string;
  accessEntryStatus?: AccessEntryStatus;
  accessEntry?: {
    doorName?: string;
    doorId?: string;
    date?: Date;
    status?: AccessEntryStatus;
    checked?: boolean;
  }[]
}

export interface reasonExtendTime {
  type: string,
  value: string
}

export class InvitationExtendReq {
  frequentAccessId: string;
  payload: InvitationExtendTimeDTO;
}

export class InvitationExtendTimeDTO {
  invitationId: string;
  hours: number;
  min: number;
  reason: string;
}

export interface extendDetail {
  reason: string;
  userExtended: string;
  hourExtend: Date[];
}


export enum PersonInvitationType {
  FAMILY = 'family',
  TRAINER = 'trainer',
  EMPLOYEE = 'employee',
  PROVIDER = 'provider',
  GUEST = 'guest',
  VISIT = 'visit',    // NUEVO
  PATIENT = 'patient', // NUEVO
  COLLABORATOR = 'collaborator'  // NUEVO
}

export class WeekDay {
  entryTime: string;
  departureTime: string;
}

export class ScheduleAccess {
  '0': WeekDay[];
  '1': WeekDay[];
  '2': WeekDay[];
  '3': WeekDay[];
  '4': WeekDay[];
  '5': WeekDay[];
  '6': WeekDay[];
}

export class ScheduleConfig {
  allDaysAccess: boolean;
  sameSchedule: boolean;
}

export class UpdateFrequentAccessControlDTO {
  schedule: ScheduleAccess;
  personInvitationType: PersonInvitationType;
  scheduleConfig: ScheduleConfig;
  dueDate?: Date | string;
  comment?: string;
}

export class Vehicle {
  plate?: string;
  color?: string;
  model?: string;
  brand?: string;
  typeVehicle: string;
}

export class CreateFrequentAccessControlDTO {
  schedule: ScheduleAccess;
  accessType: AccessType;
  unitId: any;
  personInvitationType: PersonInvitationType;
  guestsInput: {
    name: string;
    dni: string;
    phone: {
      phone: string;
      phoneDetail?: {
        countryCode: string;
        dialCode: string;
        e164Number: string;
        nationalNumber: string;
      }
    }
    base64?: string;
    imageFormat?: string;
    email?: string;
  };
  scheduleConfig: ScheduleConfig;
  qr?: string;
  dueDate?: Date;
  comment?: string;
  vehicle?: Vehicle;
}

export interface ResponsePaginatedFrenquentAccess {
  data: Paginated
}

interface Paginated {
  totalDocs: number,
  limit: number,
  page: number,
  totalPages: number,
  nextPage: number,
  prevPage: number,
  pagingCounter: number,
  hasPrevPage: boolean,
  hasNextPage: boolean,
  docs: FrequentAccess[]
}

export class FileUpload {
  size: number;
  filename: string;
  type: string;
  url: string;
  id: string;
  nameServer: string;
}
export interface Identification {
  original?: FileUpload;
  small?: FileUpload;
};

export class FrequentAccessControlDTO {
  id: string;
  guest: {
    id: string;
    name: string;
    dni: string;
    phone: string;
    phones?: string[];
    photo?: string;
    email?: string;
    identification?: Identification;
  };
  building: any;
  unit: {
    number: string;
    id: string;
  }
  editable: boolean; // SI el acceso fue creado por un residente no puede ser editado ni eliminado
  accessType: AccessType;
  personInvitationType: PersonInvitationType;
  status: AccessCardStatus;
  schedule: ScheduleAccess;
  scheduleConfig?: {   // Solo aplica para los accesos creados desde apertura de puertas
    allDaysAccess: boolean;
    sameSchedule: boolean;
  }
  dueDate?: Date | string;
  qr?: string;
  createdAt?: Date;
  comment?: string;
  lastAccess?: Date; // Para los casos que aplique (Fecha de ultimo acceso)
  vehicle?: Vehicle;
}

export class FrequentAccess {
  user: User | string;
  guest: any;
  unit: Unit | string;
  building: Building | string;
  status: AccessCardStatus;
  config: Config;
  isValidTime?: boolean;
  accessType: AccessType; ////// NUEVO
  frequentAccessToken: string;
  qr: string;
  accessConfig: {  ////// NUEVO
    dinamicLink: string;
  }
  search: String[];
  dueDate: Date | null;
  comment?: string;
  schedule: ScheduleAccess;
  scheduleConfig: ScheduleConfig;
  updatedAt?: Date;
  createdAt?: Date;
}
