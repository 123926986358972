<mat-form-field *ngIf="control" appearance="outline" [floatLabel]="floatLabelType">
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <input type="text" [placeholder]="placeholder" matInput [matAutocomplete]="auto" [matAutocomplete]="auto"
    #triggerC="matAutocompleteTrigger" #inputRef [formControl]="control">
  <mat-icon matSuffix *ngIf="!isOpenPanel && !control?.value" (click)="openOrClosePanel($event, triggerC)"
    fontSet="material-icons-outlined">keyboard_arrow_up</mat-icon>
  <mat-icon matSuffix *ngIf="isOpenPanel && !control?.value" (click)="openOrClosePanel($event, triggerC)"
    fontSet="material-icons-outlined">keyboard_arrow_down</mat-icon>
  <mat-icon fontSet="material-icons-round" matSuffix *ngIf="control?.value" (click)="onClear()">cancel</mat-icon>

  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (closed)="isOpenPanel = true"
    (opened)="isOpenPanel = false" (optionSelected)="onSelect($event.option.value)" [displayWith]="displayFn">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">{{ displayFn(option) }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>