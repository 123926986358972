<form [formGroup]="dateRangeForm">
  <mat-form-field appearance="outline">
    <mat-date-range-input [rangePicker]="picker" (click)="picker.open()">
      <input matStartDate [placeholder]="placeholderStart" formControlName="startDate" />
      <input matEndDate [placeholder]="placeholderEnd" formControlName="endDate" (dateChange)="selectDateRange()" />
    </mat-date-range-input>

    <mat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon fontSet="material-icons-outlined" matDatepickerToggleIcon *ngIf="isPickerOpen">
        keyboard_arrow_up
      </mat-icon>
      <mat-icon fontSet="material-icons-outlined" matDatepickerToggleIcon *ngIf="!isPickerOpen">
        keyboard_arrow_down
      </mat-icon>
    </mat-datepicker-toggle>

    <mat-date-range-picker #picker (opened)="isPickerOpen = false"
      (closed)="isPickerOpen = false"></mat-date-range-picker>
  </mat-form-field>
</form>