import { Injectable, Injector } from '@angular/core';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { UserService } from './user.service';

@Injectable()
export class InvitationService extends DataService {
  headers: HttpHeaders;

  constructor(private http: HttpClient, protected injector: Injector, protected store: Store<any>, private userService: UserService) {
    super(injector, store);
    let building = this.userService.getBuilding();
    this.headers = new HttpHeaders({
      authorization: JSON.parse(localStorage.getItem('currentUser')).token,
      'fb-token': JSON.parse(localStorage.getItem('currentUser')).fbToken,
    });
    if(building) this.headers.append('buildingId', building.id);
  }

  fetchInvitations(page, limit, search, find?) {
    const params = search ? `&${search}` : '';
    find = find ? `&search=${find}` : '';
    return this.http
      .get(`${environment.apiUrl}/invitation?page=${page}&rowsPerPage=${limit}${params}${find}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getInvitations(params:any) {
    let parameters = new HttpParams();
    !params.typeVisit ? null :  parameters = parameters.set("type",params.typeVisit);
    !params.unit ? null :  parameters = parameters.set("unit",params.unit);
    !params.startDate ? null :  parameters = parameters.set("startDate",params.startDate);
    !params.endDate ? null :  parameters = parameters.set("endDate",params.endDate);
    !params.search ? null :  parameters = parameters.set("search",params.search);
    parameters = parameters.set("page",params.page);
    parameters = parameters.set("size",params.size);
    return this.http.get(`${environment.apiUrl}/admin/v1/invitations`, { params: parameters });
  }

  getGroupStatusInvitations(){
    return this.http.get(`${environment.apiUrl}/admin/v1/invitations/graphic`);
  }

  fetchInvitationsParams(params: string) {
    return this.http
      .get(`${environment.apiUrl}/admin/v1/invitations?${params}`)
      .toPromise()
      .then((res) => {
        return res || [];
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  storeInvitation(invitation) {
    return this.http
      .post(`${environment.apiUrl}/invitation`, invitation)
      .toPromise()
      .then((res: any) => {
        return res || [];
      })
      .catch((err) => {
        console.log('errr: ', err);
        return Promise.reject(err);
      });
  }

  updateStatus(id, data) {
    return this.http
      .put(`${environment.apiUrl}/invitation/${id}/update-status`, data)
      .toPromise()
      .then((res: any) => {
        return res || [];
      })
      .catch((err) => {
        console.log('errr: ', err);
        return Promise.reject(err);
      });
  }

  exportVisitantsHistory(formValue) {
    let params = new HttpParams()
      .append("endDate", formValue.endDate)
      .append("startDate", formValue.startDate)

    formValue.unit ? params = params.set("unit", formValue.unit) : null;
    formValue.search ? params = params.set("search", formValue.search) : null
    formValue.type ? params = params.set("type", formValue.type) : null
    formValue.format ? params = params.set("format", formValue.format) : null

    if (formValue.insideBuilding == true || formValue.insideBuilding == false) params = params.set("insideBuilding", formValue.insideBuilding);

    return this.http.get(`${environment.apiUrl}/admin/v1/invitations/download`, { params })
  }

  generateFilePdfOrExcel(buidingId: string, params: HttpParams) {
    return this.http.get(`${environment.apiUrl}/super/v1/invitations/${buidingId}/download`, { params })
  }
}
