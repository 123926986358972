import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';

export interface OutputFilterDatepicker {
  startDate: string,
  endDate: string
}
@Component({
  selector: 'datepicker-library',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatepickerComponent implements OnInit {
  @Input() placeholderStart: string;
  @Input() placeholderEnd: string;

  isPickerOpen = false;

  dateRangeForm = new FormGroup({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  });

  @Output() outputFilter: EventEmitter<OutputFilterDatepicker> = new EventEmitter<OutputFilterDatepicker>();

  params: HttpParams = new HttpParams();

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
  }

  selectDateRange() {
    const startDate = this.dateRangeForm.get('startDate')?.value;
    const endDate = this.dateRangeForm.get('endDate')?.value;

    if (!startDate || !endDate) return;

    this.isPickerOpen = true;

    const payload: OutputFilterDatepicker = {
      startDate: this.commonService.getStartDate(startDate),
      endDate: this.commonService.getEndDate(endDate)
    }

    this.outputFilter.emit(payload);
  }
}
