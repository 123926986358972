import { Building } from './buildings.interface';

export interface Unit {
  id: string;
  building: Building | string;
  building_id: Building | string;
  number: string;
  isAdministration: boolean;
  search: string[];
  hasUserActive?: boolean;
  status: string;
  updatedAt?: Date;
  createdAt?: Date;
  updated_at?: Date;
  created_at?: Date;
}

export enum UnitStatus {
  active = 'active',
  inactive = 'inactive',
  deleted = 'deleted',
}

export enum UnitErrorCode {
  codeAlreadyExist = 'unit/already-exist',
}
export interface infoUserModal {
  ToAccept: string;
  image?: string;
  title?: string;
  description?: string;
}

export interface unitsResponse {
  unitId: string;
  unitNumber: string;
}

export enum ColumnsTableUnit {
  units = 'units',
  status = 'status',
  userActive = 'userActive',
  actions = 'actions',
}