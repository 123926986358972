<div class="row" style="position: relative">
  <div class="col-12">
    <div class="card wrapper--custom-card">
      <div class="card-body">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-4 d-flex align-items-stretch">
            <div class="d-flex flex-column w-100 px-2">
              <div class="form-group">
                <label class="font-weight-bold d-flex align-items-center">
                  <div style="width: 90%">{{ 'modal.name' | translate }} <span class="text-danger">*</span></div>
                  <span class="tooltip-button" placement="top" ngbTooltip="{{ 'modal.nameTool' | translate }}">?</span>
                </label>
                <input type="text" class="form-control" placeholder="{{ 'modal.placeholder.name' | translate }}"
                  [value]="area.name" [disabled]="true" />
              </div>
              <div class="form-group">
                <label class="font-weight-bold">
                  <div style="width: 90%">{{ 'modal.capacity' | translate }} <span class="text-danger">*</span></div>
                  <span class="tooltip-button" placement="top"
                    ngbTooltip="{{ 'modal.capacityTool' | translate }}">?</span>
                </label>
                <input type="number" class="form-control" placeholder="{{ 'modal.placeholder.capacity' | translate }}"
                  [value]="area.capacity" [disabled]="true" />
              </div>

              <div class="form-group">
                <label class="font-weight-bold">
                  <div style="width: 90%">{{ 'modal.timeMax' | translate }} <span class="text-danger">*</span></div>
                  <span class="tooltip-button" placement="top"
                    ngbTooltip="{{ 'modal.timeMaxTool' | translate }}">?</span>
                </label>
                <input type="number" class="form-control" placeholder="{{ 'modal.placeholder.timeMax' | translate }}"
                  [value]="area.maxBlock" [disabled]="true" />
              </div>
              <!-- Dias Disponibles -->
              <div class="form-group">
                <label class="font-weight-bold">
                  <div style="width: 90%">{{ 'modal.availableDays' | translate }} <span class="text-danger">*</span>
                  </div>
                  <span class="tooltip-button" placement="top"
                    ngbTooltip="{{ 'modal.availableDaysTool' | translate }}">?</span>
                </label>
                <ng-select class="custom" [multiple]="true" [(ngModel)]="availableDaysCustom"
                  [disabled]="true"></ng-select>
              </div>
              <!-- Hora de activacion de la reserva del area -->
              <div class="form-group">
                <label class="font-weight-bold">
                  <div style="width: 90%; white-space: break-spaces">{{ 'modal.activationTimeReservation' | translate
                    }} <span class="text-danger">*</span></div>
                  <span class="tooltip-button" placement="top"
                    ngbTooltip="{{ 'modal.activationTimeReservation' | translate }}">?</span>
                </label>
                <ngb-timepicker [disabled]="true" [(ngModel)]="activationTime" [size]="'small'"
                  [spinners]="true"></ngb-timepicker>
              </div>

              <div class="form-group">
                <label class="font-weight-bold mr-2">
                  <div style="width: 90%">{{ 'modal.availableHours' | translate }} <span class="text-danger">*</span>
                  </div>
                  <span class="tooltip-button" placement="top"
                    ngbTooltip="{{ 'modal.availableHoursTool' | translate }}">?</span>
                </label>

                <a href="javascript:void(0);" (click)="openHoursAvailableModal(content)" class="btn btn-primary">{{
                  'modal.select' | translate }}</a>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12">
                    <label class="font-weight-bold">
                      <div style="width: 90%">{{ 'modal.multipleReservations' | translate }} <span
                          class="text-danger">*</span></div>
                      <span class="tooltip-button" placement="top"
                        ngbTooltip="{{ 'modal.multipleReservationsTool' | translate }}">?</span>
                    </label>

                    <input type="text" class="form-control"
                      placeholder="{{ 'modal.placeholder.multipleReservations' | translate }}" [disabled]="true"
                      [value]="area.multipleReservations ? ( 'adverb.yes' | translate ) : ( 'adverb.no' | translate )" />
                  </div>

                  <div *ngIf="area.multipleReservations" class="col-12">
                    <label class="font-weight-bold">
                      <div style="width: 90%">{{ 'modal.maxCapacityUnit' | translate }} <span
                          class="text-danger">*</span></div>
                      <span class="tooltip-button" placement="top"
                        ngbTooltip="{{ 'modal.maxCapacityUnitTool' | translate }}">?</span>
                    </label>
                    <input type="number" class="form-control" placeholder="{{
                        'modal.placeholder.maxCapacityUnit' | translate
                      }}" min="0" [value]="area.maxCapacityUnit" [disabled]="true" />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="font-weight-bold">
                  <div style="width: 90%">{{ 'modal.message' | translate }}</div>
                  <span class="tooltip-button" placement="top"
                    ngbTooltip="{{ 'modal.messageTool' | translate }}">?</span>
                </label>
                <textarea [disabled]="true" [value]="area.comments" class="form-control"></textarea>
              </div>
            </div>
          </div>

          <!-- SEGUNDA COLUMNA -->
          <div class="col-xs-12 col-sm-12 col-md-4 d-flex align-items-stretch">
            <div class="d-flex flex-column w-100 px-2"> <!--NO FREE-->
              <div class="form-group">
                <label class="font-weight-bold">
                  <div style="width: 90%">{{ 'modal.daysAdvance' | translate }} <span class="text-danger">*</span>
                  </div>
                  <span class="tooltip-button" placement="bottom"
                    ngbTooltip="{{ 'modal.daysAdvanceTool' | translate }}">?</span>
                </label>
                <input type="number" [disabled]="true" class="form-control" placeholder="" min="0"
                  [value]="area.daysAdvance" />
              </div>
              <div class="form-group">
                <label class="font-weight-bold">
                  <div style="width: 90%">{{ 'modal.anticipationDays' | translate }} <span class="text-danger">*</span>
                  </div>
                  <span class="tooltip-button" placement="top"
                    ngbTooltip="{{ 'modal.anticipationDaysTool' | translate }}">?</span>
                </label>
                <input type="number" [disabled]="true" class="form-control" placeholder="" min="0"
                  [value]="area.anticipationDays" />
              </div>
              <div class="form-group">
                <label class="font-weight-bold">
                  <div style="width: 90%">{{ 'modal.timesByWeek' | translate }} <span class="text-danger">*</span>
                  </div>
                  <span class="tooltip-button" placement="top"
                    ngbTooltip="{{ 'modal.timesByWeekTool' | translate }}">?</span>
                </label>
                <input type="number" [disabled]="true" class="form-control" placeholder="" min="0"
                  [value]="area.timesByWeek" />
              </div>
              <div class="form-group">
                <label class="font-weight-bold">
                  <div style="width: 90%">{{ 'modal.timesByDay' | translate }} <span class="text-danger">*</span>
                  </div>
                  <span class="tooltip-button" placement="top"
                    ngbTooltip="{{ 'modal.timesByDayTool' | translate }}">?</span>
                </label>
                <input type="number" [disabled]="true" class="form-control" placeholder="" min="0"
                  [value]="area.timesByDay" />
              </div>
              <div class="form-group">
                <label class="font-weight-bold">
                  <div style="width: 90%">{{ 'modal.adminApproving' | translate }} <span class="text-danger">*</span>
                  </div>
                  <span class="tooltip-button" placement="top"
                    ngbTooltip="{{ 'modal.adminApprovingTool' | translate }}">?</span>
                </label>

                <input type="text" class="form-control"
                  placeholder="{{ 'modal.placeholder.adminApproving' | translate }}"
                  [value]="area.adminApproving ? ( 'adverb.yes' | translate ) : ( 'adverb.no' | translate )"
                  [disabled]="true" />
              </div>
              <div class="form-group">
                <div class="row">
                  <div [ngClass]="{
                      'col-md-6': area.payArea,
                      'col-md-12': !area.payArea
                    }">
                    <label class="font-weight-bold">
                      <div style="width: 90%">{{ 'modal.payArea' | translate }} <span class="text-danger">*</span>
                      </div>
                      <span class="tooltip-button" placement="top"
                        ngbTooltip="{{ 'modal.payAreaTool' | translate }}">?</span>
                    </label>
                    <input type="text" class="form-control" placeholder="{{ 'modal.placeholder.payArea' | translate }}"
                      [value]="area.payArea ? ( 'adverb.yes' | translate ) : ( 'adverb.no' | translate )"
                      [disabled]="true" />
                  </div>
                  <div *ngIf="area.payArea" [ngClass]="{ 'col-md-6': area.payArea }">
                    <label class="font-weight-bold">
                      <div style="width: 90%">{{ 'modal.amount' | translate }} <span class="text-danger">*</span>
                      </div>
                      <img class="ml-2" src="https://munily-public-cdn.s3.amazonaws.com/general/icons/tooltip_icon.png"
                        placement="top" ngbTooltip="{{ 'modal.amountTool' | translate }}">
                    </label>
                    <input type="number" class="form-control" placeholder="{{
                        'modal.placeholder.amount' | translate
                      }}" min="0" [value]="area.amount" [disabled]="true" />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label class="font-weight-bold">
                  <div style="width: 90%">{{ 'modal.status' | translate }} <span class="text-danger">*</span></div>
                  <span class="tooltip-button" placement="top"
                    ngbTooltip="{{ 'modal.statusTool' | translate }}">?</span>
                </label>
                <input type="text" class="form-control" placeholder="{{
                                        'modal.placeholder.status' | translate
                                      }}" min="0" [value]="('status.' + area.status | translate)" [disabled]="true" />
              </div>


              <div class="form-group">
                <label class="font-weight-bold">
                  <div style="width: 90%">{{ 'modal.blockSize' | translate }} <span class="text-danger">*</span></div>
                  <span class="tooltip-button" placement="top" ngbTooltip="{{ 'modal.block' | translate }}">?</span>
                </label>

                <input type="text" class="form-control" placeholder="{{
                                                          'modal.placeholder.blockSize' | translate
                                                        }}" min="0" [value]="area.blockSize ?? ''" [disabled]="true" />
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-4 d-flex align-items-stretch" id="badge_area"
            style="overflow: auto; max-height: 620px;">
            <div class="d-flex flex-column w-100 px-2">
              <div class="row">
                <div class="col-12">
                  <p class="text-danger pt-2">
                    {{ 'menu.areas' | translate }}
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 col-4 py-2" *ngFor="let area of allAreas">
                  <div class="position-relative content-btn-image-area">
                    <div class="imagen" [ngClass]="{ selected: area.selected }">
                      <img class="image-area" src="{{ area.urlv3 ?? area.url}}" />
                    </div>
                    <div class="text-center text-uppercase text-muted text-small py-3">
                      {{ area.name | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="available-hours-modal" style="position: relative">
  <ng-template #content let-d="dismiss">
    <div class="modal-header">
      <button type="button" class="btn btn-outline-primary" (click)="d()"> {{ 'building.tab.settings.close' |
        translate}} </button>
    </div>
    <div class="modal-body">
      <table class="table table-bordered">
        <thead>
          <th>Horas</th>
          <th *ngFor="let day of days" [ngClass]="{disabled: isDaySelected(day.value)}">{{ day.name }}</th>
        </thead>
        <tbody>
          <tr *ngFor="let hour of hours">
            <th style="cursor: not-allowed;">{{ showFormattedHour(hour) }}</th>
            <td style="cursor: not-allowed;" *ngFor="let day of days" class="clickable-cell"
              [ngClass]="{active: isHourSelected(hour, day.value), disabled: isDaySelected(day.value)}">
              <span></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </ng-template>
</div>