import { HttpParams } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'common-buttons-filled',
  templateUrl: './buttons-filled.component.html',
  styleUrls: ['./buttons-filled.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ButtonsFilledComponent implements OnInit {
  @Input() label: string;
  @Input() icon: string;
  @Input() disabled: boolean = false;
  @Input() typeIcon: 'outlined' | 'rounde'
  @Input() iconPosition: boolean = true;

  @Output() output: EventEmitter<boolean> = new EventEmitter<boolean>();


  constructor() { }

  ngOnInit(): void { }

  onClick() {
    this.output.emit(true);
  }
}
