import { RouteInfo } from './sidebar.metadata';

export enum mainFeatures {
  dashboard = "dashboard",
  dashboardMacro = "dashboardMacro",
  dashboardSuperAdmin = "dashboardSuperAdmin",
  buildings = "buildings",
  buildingsAdmin = "buildingsAdmin",
  notices = "notices",
  noticesMacro = "noticesMacro",
  releases = "releases",
  areas = "areas",
  areasMacro = "areasMacro",
  reservations = "reservations",
  reservationsMacro = "reservationsMacro",
  visits = "visits",
  registrations = "registrations",
  communities = "comunities",
  services = "services",
  accountStatements = "accountStatements",
  chats = "chats",
  frequentAccess = "frequentAccess",
  administration = "administration",
  administrativeDropdown = "administrativeDropdown",
  administrativeMacroDropdown = "administrativeMacroDropdown",
  users = "users",
  usersMacro = "usersMacro",
  usersSuperAdmin = "usersSuperAdmin",
  units = "units",
  pqrs = "pqrs",
  doorOpening = "doorOpening",
  accessManagement = "accessManagement",
  securityCenters = "securityCenters",
  munilyPay = "munilyPay",
  munilyPayTransactions = "munilyPayTransactions",
  controlAccess = "controlAccess",
  doorOpeningAdmin = "doorOpeningAdmin",
  munilyProviders = "munilyProviders",
  vigilantCalls = "vigilantCalls",
  knowledgeCenter = "knowledgeCenter",
  door = 'door',
  accessControlMacroAdmin = 'accessControlMacroAdmin',
  accessControlMacroBusiness = 'accessControlMacroBusiness',
  securityCenterMacroAdmin = 'securityCenterMacroAdmin',
  securityCenterMacroBusiness = 'securityCenterMacroBusiness',
  emergency = 'emergencies'
}

export let ROUTES: any = {};

ROUTES[mainFeatures.dashboard] = {
  path: '/app/dashboard',
  title: 'menu.home',
  icon: 'home',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.dashboardMacro] = {
  path: '/app-community',
  title: 'menu.home',
  icon: 'home',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.dashboardSuperAdmin] = { //**
  path: '/app/dashboard-admin',
  title: 'menu.home',
  icon: 'home',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.buildings] = { //**
  path: '/app/buildings',
  title: 'menu.buildings',
  icon: 'moving_ministry',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.communities] = { //**
  path: '/app/communities',
  title: 'menu.communities',
  icon: 'diversity_4',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.usersSuperAdmin] = { //**
  path: '/app/user-administration',
  title: 'menu.tenants',
  icon: 'group',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.services] = { //**
  path: '/app/app-services-super',
  title: 'menu.phservices',
  icon: 'savings',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.releases] = { //**
  path: '/app/app-notice-munily-super',
  title: 'menu.releases',
  icon: 'brand_awareness',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.accountStatements] = {
  path: '/app/onboarding/accounts-status',
  title: 'menu.accountStatements',
  icon: 'leaderboard',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.notices] = {
  path: '/app/noticesv2',
  title: 'menu.notices',
  icon: 'brand_awareness',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.noticesMacro] = {
  path: '/app-community/notices',
  title: 'macroCommunity.notice.noticesPath',
  icon: 'brand_awareness',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.chats] = {
  path: '/app/messages',
  title: 'menu.chats',
  icon: 'chat',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.reservations] = {
  path: '/app/reservations',
  title: 'menu.reservations',
  icon: 'today',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.reservationsMacro] = {
  path: '/app-community/reservations',
  title: 'menu.reservations',
  icon: 'today',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.visits] = {
  path: '/app/visits',
  title: 'menu.invitations',
  icon: 'groups',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.frequentAccess] = {
  path: '/app/frequent-access/list',
  title: 'menu.frequent-access',
  icon: 'event_repeat',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.users] = {
  path: '/app/tenants',
  title: 'menu.tenants',
  icon: '',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.usersMacro] = {
  path: '/app-community/user',
  title: 'menu.tenants',
  icon: '',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.registrations] = {
  path: '/app/registration',
  title: 'menu.preRegistration',
  icon: '',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.administration] = {
  path: '/app/administrative',
  title: 'menu.administrative',
  icon: '',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.areas] = {
  path: '/app/areas',
  title: 'menu.areas',
  icon: '',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.areasMacro] = {
  path: '/app-community/areas',
  title: 'menu.areas',
  icon: '',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.buildingsAdmin] = {
  path: '/app/buildings-admin',
  title: 'menu.buildings',
  icon: '',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.accessManagement] = {
  path: '/app/door-opening/access',
  title: 'menu.accessManagement',
  icon: '',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.securityCenters] = {
  path: '/app/door-opening/security',
  title: 'menu.securityCenter',
  icon: '',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.pqrs] = {
  path: '/app/pqrs',
  title: 'menu.pqrs',
  icon: 'markunread_mailbox',
  class: '',
  extralink: false,
  submenu: [],
} as RouteInfo;

ROUTES[mainFeatures.administrativeDropdown] = {
  path: '',
  title: 'menu.administration',
  icon: 'account_balance_wallet',
  class: 'has-arrow',
  extralink: false,
  submenu: [
    ROUTES[mainFeatures.users],
    ROUTES[mainFeatures.registrations],
    ROUTES[mainFeatures.administration],
    ROUTES[mainFeatures.areas],
    ROUTES[mainFeatures.buildingsAdmin]
  ]
} as RouteInfo;

ROUTES[mainFeatures.administrativeMacroDropdown] = {
  path: '',
  title: 'menu.administration',
  icon: 'account_balance_wallet',
  class: 'has-arrow',
  extralink: false,
  submenu: [
    ROUTES[mainFeatures.usersMacro],
    ROUTES[mainFeatures.areasMacro]
  ]
} as RouteInfo;

ROUTES[mainFeatures.doorOpening] = {
  path: '',
  title: 'Apertura de puertas',
  icon: 'switch_access_2',
  class: 'has-arrow',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.munilyPay] = {
  path: '/app/munily-pay/accounts',
  title: 'Munily Pay',
  icon: 'payments',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;


ROUTES[mainFeatures.munilyPayTransactions] = {
  path: '/app/munily-pay/transactions',
  title: 'Munily Pay',
  icon: 'payments',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.doorOpeningAdmin] = {
  path: '/app/door-opening-admin/access',
  title: 'Apertura de puertas',
  icon: '',
  class: '',
  extralink: false,
  submenu: []
} as RouteInfo;

ROUTES[mainFeatures.controlAccess] = {
  path: '',
  title: 'Control de acceso',
  icon: 'switch_access_2',
  class: 'has-arrow',
  extralink: false,
  submenu: [
    ROUTES[mainFeatures.doorOpeningAdmin]
  ]
} as RouteInfo;

ROUTES[mainFeatures.munilyProviders] = {
  path: 'https://munily.com/proveedores/',
  title: 'munilyProviders.title',
  icon: 'verified',
  class: '',
  extralink: false,
  submenu: [],
  new: true,
  externalLink: true
} as RouteInfo;

ROUTES[mainFeatures.vigilantCalls] = {
  path: '/app/vigilant-calls',
  title: 'menu.calls',
  icon: 'call_log',
  class: '',
  extralink: false,
  submenu: [],
  new: false,
  externalLink: false
} as RouteInfo;

ROUTES[mainFeatures.knowledgeCenter] = {
  path: 'https://centrodeayuda.munily.com/es/administrador',
  title: 'menu.knowledgeCenter',
  icon: 'help_outline',
  class: '',
  extralink: false,
  submenu: [],
  new: true,
  externalLink: true
} as RouteInfo;

ROUTES[mainFeatures.accessControlMacroAdmin] = {
  path: '/app-community/door-opening-macro-admin/access',
  title: 'Gestión de accesos',
  icon: 'switch_access_2',
  class: '',
  submenu: [],
  new: false,
  extralink: false,
  externalLink: false
} as RouteInfo;

ROUTES[mainFeatures.accessControlMacroBusiness] = {
  path: '/app/door-opening-business/access',
  title: 'Gestión de accesos',
  icon: 'switch_access_2',
  class: '',
  submenu: [],
  new: false,
  extralink: false,
  externalLink: false
} as RouteInfo;

ROUTES[mainFeatures.securityCenterMacroAdmin] = {
  path: '/app-community/door-opening-macro-admin/security',
  title: 'Centro de seguridad',
  icon: 'local_police',
  class: '',
  submenu: [],
  new: false,
  extralink: false,
  externalLink: false
} as RouteInfo;

ROUTES[mainFeatures.securityCenterMacroBusiness] = {
  path: '/app/door-opening-business/security',
  title: 'Centro de seguridad',
  icon: 'local_police',
  class: '',
  submenu: [],
  new: false,
  extralink: false,
  externalLink: false
} as RouteInfo;

ROUTES[mainFeatures.door] = {
  path: '/app/buildings/door',
  title: 'menu.door',
  icon: 'door_open',
  class: '',
  extralink: false,
  submenu: [],
  new: false,
  externalLink: false
} as RouteInfo;

ROUTES[mainFeatures.emergency] = {
  path: '/app/emergencies',
  title: 'menu.emergency',
  icon: 'e911_emergency',
  class: '',
  extralink: false,
  submenu: [],
  new: true,
  externalLink: false
} as RouteInfo;

export const SUB_MENU_ITEMS = [
  { submenu: mainFeatures.securityCenters, main: mainFeatures.doorOpening },
  { submenu: mainFeatures.accessManagement, main: mainFeatures.doorOpening }
];



