import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Area, AvailableDaysArea } from 'src/app/interface/area.inteface';
import * as moment from 'moment-timezone'
import { AreaService } from 'src/app/services/area.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-area-detail',
  templateUrl: './area-detail.component.html',
  styleUrls: ['./area-detail.component.scss']
})
export class AreaDetailComponent implements OnInit {
  @Input() area: Area;
  days = [
    { name: 'Domingo', value: 0, selected: false },
    { name: 'Lunes', value: 1, selected: false },
    { name: 'Martes', value: 2, selected: false },
    { name: 'Miércoles', value: 3, selected: false },
    { name: 'Jueves', value: 4, selected: false },
    { name: 'Viernes', value: 5, selected: false },
    { name: 'Sábado', value: 6, selected: false },
  ]
  hours = [...Array(24).keys()];
  activationTime: NgbTimeStruct = { hour: null, minute: null, second: null };
  selectedHours: AvailableDaysArea;
  selectedDays: any;
  allAreas: any[] = [];
  imageSelected: any;
  availableDaysCustom = [];

  constructor(private modalService: NgbModal, private areaService: AreaService, private translateService: TranslateService, private toastService: ToastrService) { }

  ngOnInit(): void {
    this.activationTime = this.formatTime(this.area.activationTime, 'time') as NgbTimeStruct;
    this.selectedDays = this.setAvailableDays(this.area.availableDays);
    this.selectedHours = this.area.availableDays;
    this.availableDaysCustom = this.selectedDays.map(day => this.translateService.instant('availableDays.' + this.days[day].name));
    this.getAreasImages();
  }

  formatTime(time: any, formatTo: string): NgbTimeStruct | string {
    if (formatTo == 'time') {
      let dateTime = time.split(':');
      return { hour: Number(dateTime[0]), minute: Number(dateTime[1]), second: 0 };
    } else if (formatTo == 'date') {
      return `${time.hour}:${time.minute < 9 ? '0' + time.minute : time.minute}`;
    }
  }

  openHoursAvailableModal(content) {
    this.modalService.open(content, { windowClass: 'available-hours-modal', size: 'lg' }).result.then((result) => {
    }, (reason) => { });
    return false;
  }

  showFormattedHour(hour) {
    return moment(hour, "H").format('HH:mm');
  }


  isHourSelected(hour, day) {
    let formattedHour = this.showFormattedHour(hour);
    let selected = this.selectedHours[day].filter((value, index) => {
      return value == hour
    });

    return !(selected.length === 0);
  }

  isDaySelected(day) {
    return !this.selectedDays.includes(day);
  }

  setAvailableDays(obj) {
    if (!obj) return [];

    const keys = Object.keys(obj)
    const arrDays = []
    keys.forEach((k) => {
      if (obj[k] && obj[k].length) arrDays.push(parseInt(k))
    })
    return arrDays
  }

  getAreasImages() {
    this.areaService.getImagesofAreas().subscribe((resp: any) => {

      this.allAreas = [];
      for (let area of resp.data) {
        if (area.id == (this.area ? this.area.image.id : false)) {
          area.selected = true;
          this.imageSelected = area;
        } else {
          area.selected = false;
        }
      }
      this.allAreas = resp.data;
    }, error => {
      this.toastService.error(this.translateService.instant("areas.errors.loadImagesAreas"))
    });
  }
}
