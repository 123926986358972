import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ACTION_LOADING } from '../../component/store/actions/actions';
//Components
import { ChangePasswordComponent } from 'src/app/common-components/change-password/change-password.component';
import { ProfileComponent } from '../profile/profile.component';
//Services
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../services/auth.service';
import { CommonService } from '../../services/common.service';
import { DataService } from '../../services/data.service';
import { MessagingService } from '../../services/messaging.service';
import { NoticeService } from '../../services/notice.service';
//Others
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AccountingButtons } from 'src/app/pages/onboarding/transactions-payment/transactions-payment.component';
import { BuildingService } from 'src/app/services/building.service';
import { OnboardingService } from 'src/app/services/onboarding.service';
import { UserService } from 'src/app/services/user.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

declare var $: any;

export enum HeaderModules {
  accountState = 'onboarding',
  none = 'none'
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {
  @ViewChild('changePassword', { static: false }) changePassword: ChangePasswordComponent;
  @ViewChild('profile', { static: false }) profile: ProfileComponent;
  @ViewChild('logoutModal', { static: false }) logoutModal: ElementRef;


  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  userEmail = '';
  userName = '';
  photo = '';
  imgDefault = 'https://munily-public-cdn.s3.amazonaws.com/general/user1.jpg';
  curLang: string;
  curLangObj: any;
  unreadNotices = 0;
  building: any;
  buildingQr: any;
  param = null;
  modalRef: any;
  commonService = this.injector.get(CommonService);

  languages: any = [
    { value: 'en', img: 'https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/unitedKFlag.png', lang: 'English' },
    { value: 'es', img: 'https://munily-public-cdn.s3.amazonaws.com/shared/dashboard/spainFlag.png', lang: 'Español' },
  ];

  headerModules = HeaderModules;
  accountingButtons = AccountingButtons;
  module: HeaderModules;
  lastTransactionUpdate
  onboardingIsNotComplete = false;
  typeSelectButtons: string;
  typeButton: boolean = false;
  constructor(
    private router: Router,
    private injector: Injector,
    private authService: AuthService,
    private noticeService: NoticeService,
    public messagingService: MessagingService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private dataService: DataService,
    private activatedRoute: ActivatedRoute,
    private onboardingService: OnboardingService,
    private buildingService: BuildingService,
    private userService: UserService,
    private toastr: ToastrService,
    private location: Location,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.addSvgIcon('moving_ministry', 'assets/images/icons/moving_ministry.svg');
    if(this.buildingService.currentBuilding) this.building = this.buildingService.currentBuilding;
    else this.building = this.dataService.getBuilding();
    
    this.activatedRoute.url.subscribe(res => {

      if (this.router.url.includes(HeaderModules.accountState)) {
        this.module = HeaderModules.accountState;

        const typeHeader = this.onboardingService.getStatusHeader();
        const newType = Object.values(AccountingButtons).find(type => this.router.url.includes(type));

        if (this.typeButton && typeHeader != newType && newType !== AccountingButtons.updateAccountingImport) this.typeButton = false;
        if (!this.typeButton && newType === AccountingButtons.updateAccountingImport && typeHeader === AccountingButtons.updateAccounting) {
          this.typeSelectButtons = AccountingButtons.updateAccounting;
          this.onboardingService.setStatusHeader(typeHeader);
          return;
        }

        if (newType) {
          this.typeSelectButtons = newType;
          this.onboardingService.setStatusHeader(newType);
        }
        this.getReadyAccountingModule()
      }
      else this.module = HeaderModules.none;
    },
      err => console.error(err))
  }

  notifications: Object[] = [];

  ngOnInit() {
    const user = this.commonService.getUser();
    this.messagingService.getNotificationMessages();
    this.userEmail = user?.email;
    this.userName = user.name;
    this.photo = user.photoUrl ? user.photoUrl : this.imgDefault;

    this.authService.getLoading().subscribe((state) => {
      this.curLang = state.curLanguage;
      if (!this.curLangObj) {
        for (let language of this.languages) {
          if (language.value == this.curLang) this.curLangObj = language;
        }
      }
      this.translate.use(state.curLanguage);
    });
  }

  getReadyAccountingModule() {
    this.setLastUpdateTransactionDate()
    this.enableOrDisableAccountingButtons()
  }

  enableOrDisableAccountingButtons() {
    const buildingId = this.building.id;
    this.onboardingService.getStatusConfig(buildingId).subscribe((resp: any) => {
      this.onboardingService.setORemoveOnboardingStatus(resp.data)
      const onboardingIsNotComplete = this.onboardingService.getOnboardingIsNotComplete()
      if (onboardingIsNotComplete) this.onboardingIsNotComplete = true;
      if (!onboardingIsNotComplete) this.onboardingIsNotComplete = false;
    })
  }

  setLastUpdateTransactionDate() {
    const lastTransactionUpdate = this.onboardingService.getLastTransactionUpdate()
    if (!lastTransactionUpdate || lastTransactionUpdate == 'undefined') return this.lastTransactionUpdate = false;
    let behindComma = moment(lastTransactionUpdate).calendar().split(' at')[0]
    const afterComma = moment(lastTransactionUpdate).format('dddd MMMM YYYY')

    afterComma.split(' ').forEach(e => {
      behindComma = behindComma.replace(e, '')
    });

    this.lastTransactionUpdate = `${behindComma}, ${afterComma}`
  }

  goToAccountingPage(type: AccountingButtons) {
    if (this.typeButton) this.onboardingService.setStatusHeader(type);
    this.router.navigate([`app/onboarding/transactions/${type}`])
  }

  clickButtonAccounting(type: AccountingButtons, button: HTMLButtonElement) {
    // const buttons = document.getElementsByClassName('btnAccountingState') as HTMLCollectionOf<HTMLButtonElement>;
    // for (let i = 0; i < buttons.length; i++) {
    //   const button = buttons[i];
    //   button.className = button.className.replace('btnAccountingActive ', 'btnAccounting ')
    // }
    // button.className = button.className.replace('btnAccounting ', 'btnAccountingActive ') 
    if (type && type !== AccountingButtons.button) {
      this.goToAccountingPage(type);
      this.typeButton = false;
      this.onboardingService.setStatusHeader(type);
    }

    if (type === AccountingButtons.button) {
      this.typeSelectButtons = null;
      this.typeButton = true;
    }
  }

  setNavigationHeaderOnboarding(type: AccountingButtons) {
    console.log('tye', type);

    return AccountingButtons[type] ? 'btnAccountingActive' : 'btnAccounting'
  }

  readNotice(id, read, activity) {
    this.authService.isLoading({ action: ACTION_LOADING, payload: true });
    if (!read) {
      const user_id = this.authService.getUser()._id;
      this.noticeService
        .readNotice(id, user_id)
        .then(() => {
          this.authService.isLoading({ action: ACTION_LOADING, payload: false });
          if (activity === 'notice') this.router.navigate([`/app/notices`]);
          if (activity === 'reservation') this.router.navigate([`/app/reservations`]);
          if (activity === 'conversation') this.router.navigate([`/app/messages`]);
        })
        .catch((err) => {
          console.log('err: ', err);
          this.authService.isLoading({ action: ACTION_LOADING, payload: false });
        });
    } else {
      this.authService.isLoading({ action: ACTION_LOADING, payload: false });
      if (activity === 'notice') this.router.navigate([`/app/notices`]);
      if (activity === 'reservation') this.router.navigate([`/app/reservations`]);
      if (activity === 'conversation') this.router.navigate([`/app/messages`]);
    }
  }

  goMessage(message: any) {
    this.messagingService.notificationSelected = message;
    this.router.navigate([`/app/messages`]);
  }

  openModalPassword() {
    this.changePassword.openModal();
  }

  openModalProfile() {
    this.profile.openModal();
  }

  onConfirm(data) {
    const user = this.authService.getUser();
    if (data.photoUrl !== user.photoUrl) {
      this.photo = data.photoUrl;
      user.photoUrl = data.photoUrl;
      this.authService.setUser(user);
    }
    if (data.email !== user.email) {
      this.userEmail = data.email;
      user.email = data.email;
      this.authService.setUser(user);
    }
    if (data.name !== user.name || data.phoneDetail?.e164Number !== user.phoneDetail?.e164Number) {
      this.userName = data.name;
      user.name = data.name;
      user.phone = data.phone;
      user.phoneDetail = JSON.parse(JSON.stringify(data.phoneDetail));
      this.authService.setUser(user);
    }
  }

  logout() {
    let multisesion = this.dataService.getUserInMultisession();
    if (multisesion && multisesion.length > 1) {
      this.modalRef = this.modalService.open(this.logoutModal, {
        windowClass: 'logout-modal',
        backdropClass: 'blur-backdrop',
        backdrop: 'static',
        keyboard: false,
        centered: true,
        size: 'md'
      });
    } else {
      this.authService.logoutV2();
      this.buildingService.currentBuilding = null;
    }
  }

  logoutModalResponse(e: any) {
    if (e.logout === true) {
      this.authService.logoutV2();
      this.buildingService.currentBuilding = null;
    }
    this.modalService.dismissAll();
  }

  useLanguage(language: any) {
    const curLanguage = this.authService.setLanguage(language.value);
    this.curLang = language.value;
    this.curLangObj = language;
    this.userService.isLoading({ action: ACTION_LOADING, payload: true });
    this.userService.updateLanguage(curLanguage).subscribe((resp:any)=>{
      this.toastr.success(resp.data.message);
      this.userService.isLoading({ action: ACTION_LOADING, payload: true });
      this.refreshView();
    },error =>{
      this.userService.isLoading({ action: ACTION_LOADING, payload: true });
      this.toastr.error(error.error.message);
    });
  }

  refreshView() {
    this.location.replaceState(this.location.path());
    window.location.reload();
  }

  calculateDaysRemaining() {
    const building = this.authService.getBuilding();
    const plan = building.plan;
    let diff = 0;
    if (plan) {
      if (plan.startDate && plan.endDate) {
        const now = moment();
        const end = moment(plan.endDate, ['D/M/YYYY']);
        diff = end.diff(now, 'days');
      } else {
        const now = moment();
        const withDays = moment(building.updated_at).add(plan.freeDays, 'days');
        diff = withDays.diff(now, 'days');
      }
      if (diff <= 30) {
        this.param = { value: diff };
      } else {
        this.param = null;
      }
    }
  }

  addSvgIcon(name: string, path: string) {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
